<template>
  <footer :style="{ backgroundColor: themeConfig.themeBaseColor }">
    <div class="foot-nav-container">
      <div class="foot-logo-row hands">
        <div v-if="domainConfig?.icon" class="menu-icon-flex">
          <img v-lazy="domainConfig.icon" alt="">
        </div>
        <div class="site-name">
          {{ domainConfig.title }}
        </div>
      </div>
      <div class="foot-nav-row">
        <div class="foot-nav-item hands" @click="goto('privacy')">
          Privacy Policy
        </div>
        <div class="foot-nav-item hands" @click="goto('terms')">
          Terms Of Service
        </div>
        <div class="foot-nav-item hands" @click="goto('about')">
          About Us
        </div>
      </div>
    </div>
    <div class="corner-module">
      Copyright©2024 {{ domainConfig?.title =='NEWS'?formattedHost:domainConfig.title }}. All rights
      reserved.
    </div>
  </footer>
</template>

<script>
import '@/css/module/foot-theme-1.scss';
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      themeConfig: 'themeConfig',
      domainConfig: 'domainConfig',
      deviceType: 'deviceType',
    }),
    formattedHost () {
      if (!this.host) return 'News';
      const parts = this.host.split('.');
      if (parts.length > 0) {
        const firstPart = parts[0];
        return firstPart.charAt(0).toUpperCase() + firstPart.slice(1) + ' Media';
      }
      return '';
    }
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      // this.isOpen = false;
      // if (where == 'home' && this.$route.path == '/') {
      //   this.scrollToTop()
      //   return
      // }
      this.$router.push({ name: where });
    },
  },
};
</script>
